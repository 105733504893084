<template>
    <div class="warn">
        <!-- <i class="el-icon-warning"></i> -->

        <i class="fa fa-warning"></i>


        <!-- <img src="https://www.baidu.com/img/flexible/logo/pc/result.png" alt=""> -->
        <p>
            <span>
                <font style="color: #606060;">
                    超48小时未送医废
                </font>
            </span>
            <span>
                立即查询
            </span>
        </p>

    </div>
</template>

<script>
    export default {

        props: ["Val"],
        data() {
            return {
                // type: 0
            }
        },
        watch: {
            // typeVal(value) {
            //     this.type = this.typeVal
            //     console.log("AAAAAAAAAAAAAAAAAAAA", this.value);
            // }
        },
        methods: {


        }
    }
</script>

<style lang="scss" scoped>
    .warn {
        background: #FFFAF9;
        border: 1px solid #FA5A5C;
        box-sizing: border-box;
        border-radius: 6px;
        height: 36px;
        padding: 0 10px;
        margin-left: 10px;
        display: inline-block;
        float: right;
        color: #FA5A5C;
        font-weight: 600;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        img,
        i {

            width: 15px;
            height: 15px;
        }

        span {
            text-align: left;
            transform: scale(0.8);
            display: block;
            line-height: 12px;
        }

    }
</style>